import type { WWeddingShopNavView, WStoreNavView } from '@zola/svc-web-api-ts-client';

import { UserContext } from '@zola-helpers/client/dist/es/@types';
import isClientSide from '~/libs/client/utils/isClientSide';

import SecondaryNavWeddingShopData from './SecondaryNavWeddingShopData';
import SecondaryNavRegistryData from './SecondaryNavRegistryData';
import SecondaryNavData from './SecondaryNavData';
import { SecondaryNavType } from './types';

type InitNavDataOptions = {
  user: UserContext | null;
  showPromo: boolean;
  isShopHolidaySeason: boolean;
  is2022HolidaysEnabled: boolean;
  shopCategories?: WStoreNavView;
  weddingShopCategories?: WWeddingShopNavView;
  activePrimaryLink?: string;
  activeSecondaryLink?: string;
  disablePrimaryNavCollapse: boolean;
  disableSecondary: boolean;
  secondaryNavType?: SecondaryNavType;
};

const initNavData = ({
  user,
  showPromo,
  isShopHolidaySeason,
  is2022HolidaysEnabled,
  shopCategories,
  weddingShopCategories,
  activePrimaryLink,
  activeSecondaryLink,
  disablePrimaryNavCollapse,
  disableSecondary,
  secondaryNavType,
}: InitNavDataOptions): void => {
  const userContext = user;
  let secondaryData;

  if (!disableSecondary) {
    switch (secondaryNavType) {
      case SecondaryNavType.GUEST_LIST:
        secondaryData = new SecondaryNavData(userContext).getGuestListItems();
        break;
      case SecondaryNavType.PAPER:
        secondaryData = new SecondaryNavData(userContext, is2022HolidaysEnabled).getInvitesItems();
        break;
      case SecondaryNavType.WEBSITE:
        secondaryData = new SecondaryNavData(userContext).getWebsiteItems();
        break;
      case SecondaryNavType.REGISTRY:
        secondaryData = new SecondaryNavRegistryData(
          userContext,
          shopCategories,
          isShopHolidaySeason
        ).getItems();
        break;
      case SecondaryNavType.WEDDING_BOUTIQUE:
        secondaryData = new SecondaryNavWeddingShopData(
          userContext,
          weddingShopCategories
        ).getItems();
        break;
      case SecondaryNavType.ALBUMS:
        secondaryData = new SecondaryNavData(userContext).getAlbumsItems();
        break;
      default:
    }
  }

  if (isClientSide()) {
    window.dispatchEvent(
      new CustomEvent('INIT_NAV_DATA', {
        detail: {
          secondaryData,
          userContext,
          showPromo,
          activePrimaryLink,
          activeSecondaryLink,
          disablePrimaryNavCollapse,
        },
      })
    );
  }
};

export default initNavData;
