import React, { useEffect } from 'react';
import Head from 'next/head';
import cx from 'classnames';
import Sticky from 'react-stickynode';
import { StickyContainer } from 'react-sticky';
import type { WWeddingShopNavView, WStoreNavView } from '@zola/svc-web-api-ts-client';
import type { InitNavDataEventPayload } from '@zola-helpers/client/dist/es/constants/navConstants';

import { getNavSource } from '~/libs/client/nav';
import { UserContext } from '@zola-helpers/client/dist/es/@types';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import staticFlags from '~/libs/universal/utils/staticFlags';

import isClientSide from '~/libs/client/utils/isClientSide';
import initNavData from './initNavData';
import { SecondaryNavType } from './types';

import { NavContainerSSG } from './Nav.styles';

const {
  EVENTS: { NAV_LOADED, INIT_NAV_DATA },
} = NAV_DATA;

export type NavProps = {
  navHTML: string | null;
  shopCategories?: WStoreNavView;
  weddingShopCategories?: WWeddingShopNavView;
  user: UserContext | null;
  activePrimaryLink?: string;
  activeSecondaryLink?: string;
  showPromo?: boolean;
  isShopHolidaySeason?: boolean;
  is2022HolidaysEnabled?: boolean;
  disablePrimaryNavCollapse?: boolean;
  disableSecondary?: boolean;
  className?: string;
  secondaryNavType?: SecondaryNavType;
  ssg?: boolean;
  showCommunityHeader?: boolean;
  showCommunityTopBanner?: boolean;
  showNavV3?: boolean;
  hidden?: boolean;
};

const Nav: React.FC<NavProps> = ({
  navHTML,
  className,
  activePrimaryLink,
  showPromo = false,
  isShopHolidaySeason = false,
  is2022HolidaysEnabled = false,
  user = null,
  disablePrimaryNavCollapse = false,
  disableSecondary = false,
  activeSecondaryLink,
  shopCategories,
  weddingShopCategories,
  secondaryNavType,
  ssg = false,
  children,
  hidden = false,
}): JSX.Element => {
  const { navScript, navStyle } = getNavSource(navHTML, ssg);

  const handleInitNavData = () => {
    initNavData({
      user,
      showPromo,
      isShopHolidaySeason,
      is2022HolidaysEnabled,
      shopCategories,
      weddingShopCategories,
      activePrimaryLink,
      activeSecondaryLink,
      disablePrimaryNavCollapse,
      disableSecondary,
      secondaryNavType,
    });
  };

  useEffect(() => {
    if (isClientSide()) {
      window.addEventListener(NAV_LOADED, handleInitNavData);
    }
    return () => {
      window.removeEventListener(NAV_LOADED, handleInitNavData);
    };
  });
  // If user changes after the nav initialization, update the nav
  useEffect(() => {
    if (user) {
      window.dispatchEvent(
        new CustomEvent<Partial<InitNavDataEventPayload>>(INIT_NAV_DATA, {
          detail: {
            userContext: user,
          },
        })
      );
    }
  }, [user]);

  useEffect(() => {
    /** In case the nav is loaded before the app is ready, we need to dispatch this event to
     *  re-trigger the NAV_LOADED event */
    window.dispatchEvent(new CustomEvent('HOST_APP_READY'));
  }, []);

  const navSsrFlag = staticFlags.get('navSsr');

  return (
    <StickyContainer>
      {!navSsrFlag ? (
        <Head>
          <link rel="stylesheet" href={navStyle} />
          <script src={navScript} />
        </Head>
      ) : null}
      <Sticky innerZ={500} enableTransforms={false}>
        <NavContainerSSG newHeight hidden={hidden} id="navContainerSSG" className={cx(className)}>
          {/* web-baby only extract style and javascript files. */}
          {/* the following DOM structure should follow what's in the index.html of web-nav */}
          {navSsrFlag && navHTML && (
            <div
              id="root-nav-container"
              data-testid="root-nav"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: navHTML }}
            />
          )}
          {!navSsrFlag && (
            <div id="root-nav" data-testid="root-nav">
              {children}
            </div>
          )}
          <div id="notification-root" data-testid="notification-root" />
        </NavContainerSSG>
      </Sticky>
    </StickyContainer>
  );
};

export default Nav;
