import styled from '@emotion/styled';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

export const NavContainerSSG = styled.div<{
  hidden: boolean;
  newHeight: boolean;
}>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  height: ${({ newHeight }) => (newHeight ? 96 : 56)}px;
  ${MEDIA_QUERY.DESKTOP} {
    height: ${({ newHeight }) => (newHeight ? 128 : 136)}px;
  }
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    margin-bottom: -1px; // prevents double border when used with Page component
  }
`;
